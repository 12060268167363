<template>
  <div class="page">
    <layoutContainer>
      <div class="pay">
        <div class="order" v-if="!buyFlag">
          <p>订单：{{ orderDetail.orderCode }}</p>
          <div class="project">
            <div class="detail">产品名称：{{ orderDetail.productName }}</div>
            <p class="price">¥{{ orderDetail.originalTotalPrice }}</p>
          </div>
          <div class="project" style="flex-direction: column;">
            <div class="pintuan-info">
              <div class="pintuan-info-show">
                <div class="label" v-if="togetherDetailId">拼团</div>
                <div class="label-detail" v-if="togetherDetailId">{{ pintuanSelect.name }}</div>
                <div class="label" v-if="allCouponList.length > 0">用劵</div>
                <div class="label-detail" style="margin-right: 12px;" v-if="allCouponList.length > 0">
                  {{ `${choseCoupon.value ? `¥${choseCoupon.value}` : ''}` }}
                </div>
                <div
                  class="juan-select"
                  @click="showCouponDialog = true"
                  v-if="allCouponList.length > 0"
                  style="margin-right: 30px"
                >
                  选择
                </div>
              </div>

              <div class="yue" v-if="!(userAccount.length === 1 && Number(userAccount[0].balance) === 0)">
                <div class="label">用余额</div>
                <el-switch style="display: block;margin-right: 32px;" v-model="isUseYue"></el-switch>
                <div class="label" v-if="isUseYue">可支付 ¥{{ useAccount.yue }}</div>
                <el-input
                  v-model="password"
                  placeholder="请输入密码"
                  style="width: 230px;"
                  type="password"
                  v-if="isUseYue"
                ></el-input>
              </div>
            </div>
            <div class="line"></div>
            <div class="pintuan-info" v-if="enabled" style="margin-bottom: 0;">
              <div class="pintuan-info-show">
                <div class="label">代买</div>
                <el-switch style="display: block;margin-right: 32px;" v-model="isReplace"></el-switch>
                <el-input
                  v-model="agentSaleUserPhoneNumber"
                  placeholder="可输入对方注册帐号帮其代买"
                  style="width: 230px;"
                  type="password"
                  v-if="isReplace"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="buyed-video" v-if="buyFlag">
          <p>您已经购买过课程</p>
        </div>
        <div class="pay-type" v-if="!buyFlag">
          <p class="type-text">支付方式:</p>
          <div :class="['type', 'type-alipay', choosedPay === 'payCourse' ? 'choosed-type' : '']" @click="payCourse">
            <img
              v-show="choosedPay === 'payCourse'"
              class="choosed-icon"
              src="@/assets/choosed-pay.png"
              draggable="false"
            />
          </div>
          <div :class="['type', 'type-wechat', choosedPay === 'payWechat' ? 'choosed-type' : '']" @click="payWechat">
            <img
              v-show="choosedPay === 'payWechat'"
              class="choosed-icon"
              src="@/assets/choosed-pay.png"
              draggable="false"
            />
          </div>
          <p class="left-time" v-if="leftTime > 0">
            请在<span class="time">{{ minutes }}:{{ seconds > 9 ? seconds : `0${seconds}` }}</span
            >内完成支付即可
          </p>
          <p class="left-time" v-if="leftTime === 0">
            订单已超时
          </p>
          <div class="pay-price">
            <span class="text">应付金额:</span>
            <span
              class="pay-number"
              :style="{'margin-right': `${discountTotalPrice === orderDetail.originalTotalPrice ? '0px' : '80px'}`}"
              >¥{{ discountTotalPrice }}</span
            >
            <span
              class="pay-number"
              v-if="discountTotalPrice !== orderDetail.originalTotalPrice"
              style="text-decoration: line-through;color: #000;font-size: 30px;line-height: 56px;"
              >¥{{ orderDetail.originalTotalPrice }}</span
            >
            <div style="display: flex;justify-content: flex-end;">
              <p class="pay-btn" @click="pay()" v-if="leftTime > 0">
                立即支付
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="pay-html" v-html="payHtml"></div>
    </layoutContainer>
    <el-dialog :visible.sync="dialogVisible" width="520px">
      <p style="margin: 0 auto;display: block; text-align: center;margin: 0 auto 20px;">
        请使用微信扫描二维码 以完成支付
      </p>
      <img :src="paySrc" style="width: 240px; margin: 0 auto 20px;display: block;" />
      <p class="pay-number" style="margin: 0 auto;display: block; text-align: center;margin: 0 auto 0px;">
        ¥{{ discountTotalPrice }}
      </p>
    </el-dialog>
    <el-dialog :visible.sync="dialogYuEVisible">
      <div class="title">
        <img src="/img/more-persion.png" />
        <p class="name">选择账户</p>
      </div>
      <div class="content">
        <div class="form-pintuan">
          <div class="label-account">账户</div>
          <el-select v-model="temporaryAccount" placeholder="选择账户" value-key="id">
            <el-option v-for="item in userAccount" :key="item.id" :label="item.name" :value="item"></el-option>
          </el-select>
        </div>
        <div class="form-pintuan" v-if="temporaryAccount.id">
          <div class="label-account">可用余额</div>
          <span>¥ {{ temporaryAccount.balance }}</span>
        </div>
        <el-button
          style="width: 200px;height: 46px;background: #5AD3CD;border-radius: 4px;margin-left: 256px;"
          type="primary"
          @click="chooseAccount"
        >
          确定
        </el-button>
      </div>
    </el-dialog>
    <div v-show="showCouponDialog" class="dialog">
      <el-dialog :visible.sync="showCouponDialog" width="1000px" height="590px">
        <div slot="title" class="coupon-title">
          <img src="/img/userinfo-icon/couponIcon.png" class="coupon-icon" alt="" srcset="" />
          <span>可用代金卷</span>
        </div>
        <div style="overflow: hidden">
          <div
            :class="`coupon ${item.status === 1 ? 'coupon--geted-bg' : 'coupon-bg'}`"
            v-for="(item, idx) in allCouponList"
            :key="idx"
            @click="
              choseCoupon = item
              showCouponDialog = false
            "
            style="float: left;
            margin-left: 27px;"
          >
            <div class="coupon-price">
              <span class="price-icon">¥</span>
              <span class="price-value">{{ item.value }}</span>
            </div>
            <div class="coupon-info">
              <span>满{{ item.satisfyAmount }}减{{ item.subtractAmount }}</span>
              <br />
              <div style="height: 61px;">
                <span>{{ item.fitBusiness }}</span>
                <span>适用</span>
              </div>
              <span class="coupon-time">有效期至{{ item.validDays }}</span>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {orderPay, orderDetail, getCourseDetail, orderAccountList} from '@/api/subject'
import {getCouponList} from '../../api/orderAccount'
import {togetherOptionList, currentLoginUser} from '@/api/project'
import {getUserInfoApi} from '@/api/index'
import {getAgentSaleAuth} from '../../api/orderAccount'
import md5 from 'blueimp-md5'

export default {
  name: 'pay',
  data() {
    return {
      choosedPay: 'payCourse',
      payHtml: '',
      leftTime: 20 * 60 * 1000,
      payTime: 20 * 60 * 1000,
      minutes: 0,
      password: '',
      isReplace: false,
      hasGetWechatCode: false,
      dialogYuEVisible: false,
      hasPayPassword: false,
      pintuanList: [],
      now: new Date().getTime(),
      togetherDetailId: '',
      seconds: 0,
      showCouponDialog: false,
      timer: null,
      paySrc: '',
      isUseYue: false,
      enabled: false,
      orderDetail: {},
      dialogVisible: false,
      buyFlag: false,
      payTimer: null,
      agentSaleUserPhoneNumber: '',
      userAccount: [],
      choseCoupon: {},
      discountTotalPrice: '',
      useAccount: {},
      pintuanSelect: {},
      temporaryAccount: {},
      allCouponList: []
    }
  },
  watch: {
    isUseYue() {
      if (this.isUseYue) {
        if (!this.hasPayPassword) {
          this.$message.error('请先在个人中心设置支付密码')
        }
        this.dialogYuEVisible = true
      }
    },
    dialogYuEVisible() {
      if (!this.dialogYuEVisible && !this.useAccount.id) this.isUseYue = false
    },
    useAccount() {
      this.discountTotalPrice = (
        Number(this.discountTotalPrice || 0) -
        Number(this.pintuanSelect?.togetherPrice || 0) -
        Number(
          this.password && this.temporaryAccount?.balance
            ? this.temporaryAccount?.balance
            : this.temporaryAccount?.balance || 0
        ) -
        Number((this.choseCoupon && this.choseCoupon.value) || 0)
      ).toFixed(2)
      if (this.discountTotalPrice < 0) this.discountTotalPrice = '0.00'
    },
    choseCoupon() {
      this.discountTotalPrice = (
        Number(this.discountTotalPrice || 0) -
        Number(this.pintuanSelect?.togetherPrice || 0) -
        Number(
          this.password && this.temporaryAccount?.balance
            ? this.temporaryAccount?.balance
            : this.temporaryAccount?.balance || 0
        ) -
        Number((this.choseCoupon && this.choseCoupon.value) || 0)
      ).toFixed(2)
      if (this.discountTotalPrice < 0) this.discountTotalPrice = '0.00'
    },
    pintuanSelect() {
      this.discountTotalPrice = (
        Number(this.discountTotalPrice || 0) -
        Number(this.pintuanSelect?.togetherPrice || 0) -
        Number(
          this.password && this.temporaryAccount?.balance
            ? this.temporaryAccount?.balance
            : this.temporaryAccount?.balance || 0
        ) -
        Number((this.choseCoupon && this.choseCoupon.value) || 0)
      ).toFixed(2)
      if (this.discountTotalPrice < 0) this.discountTotalPrice = '0.00'
    }
  },
  created() {
    const {orderId, togetherDetailId, courseId} = this.$route.query
    console.log('%c [ togetherDetailId ]-252', 'font-size:13px; background:pink; color:#bf2c9f;', togetherDetailId)
    this.togetherDetailId = togetherDetailId
    this.showTogetherDialog = true
    const params = {
      productId: courseId,
      businessType: '知识视频'
    }
    getAgentSaleAuth().then(res => {
      this.enabled = res.data.enabled === 1 ? true : false
    })
    currentLoginUser().then(res => {
      console.log('%c [ res ]-79', 'font-size:13px; background:pink; color:#bf2c9f;', res)
      this.phoneNumber = res.data.phoneNumber
      getUserInfoApi({phone: this.phoneNumber}).then(res => {
        if (res && res.data) {
          this.hasPayPassword = res.data.hasPayPassword
        }
      })
    })
    togetherOptionList(params).then(res => {
      this.pintuanList = res.data
      this.pintuanSelect = res.data.find(item => item.id == togetherDetailId)
    })
    orderDetail(orderId).then(res => {
      this.orderDetail = res.data
      this.discountTotalPrice = this.orderDetail.discountTotalPrice
      this.payTime = res.data.remainingPaymentTime
    })
    orderAccountList().then(res => {
      this.userAccount = res.data
    })
  },
  mounted() {
    this.timer = setInterval(this.countDown, 1000)
    getCouponList({
      fitBusiness: 2
    }).then(res => {
      this.allCouponList = res.data.records
      this.choseCoupon = this.allCouponList[0]
    })
  },
  beforeDestroy() {
    this.payTimer && clearInterval(this.payTimer)
    this.payTimer = null
  },
  methods: {
    payCourse() {
      this.choosedPay = 'payCourse'
    },
    chooseAccount() {
      if (Number(this.temporaryAccount.balance) === 0) {
        this.$message.error('账户余额不足')
        return
      }
      this.useAccount = this.temporaryAccount
      this.dialogYuEVisible = false
    },
    payWechat() {
      this.choosedPay = 'payWechat'
      this.getPayStatus()
    },
    getPayStatus() {
      const {courseId} = this.$route.query
      const params = {id: courseId}
      if (!this.payTimer)
        this.payTimer = setInterval(() => {
          getCourseDetail(params).then(res => {
            const {code, data, msg} = res
            if (code === 200 && data) {
              if (res.data.buyFlag) {
                this.$dialog.toast('购买成功')
                this.payTimer && clearInterval(this.payTimer)
                this.payTimer = null
                setTimeout(() => {
                  this.$router.push({
                    path: '/techVideoDetail',
                    query: {id: courseId}
                  })
                }, 200)
              }
            } else {
              this.$dialog.toast(msg)
            }
          })
        }, 1000)
    },
    pay(way) {
      let payType = ''
      if (way) {
        payType = way
      } else {
        payType = this.choosedPay === 'payCourse' ? 1 : 2
      }
      const params = {
        payType: payType,
        orderCode: this.orderDetail.orderCode,
        // alipayReturnUrl: window.location.origin + '/#/' + 'techVideoDetail?id=' + ,
        accountId: this.useAccount && this.useAccount.id ? this.useAccount.id : '',
        payPassword: md5(this.password),
        couponId: this.choseCoupon && this.choseCoupon.id ? this.choseCoupon.id : '',
        agentSaleUserPhoneNumber: this.agentSaleUserPhoneNumber
      }
      orderPay(params).then(item => {
        console.log('%c [ item ]-309', 'font-size:13px; background:pink; color:#bf2c9f;', item)
        if (payType === 2 || this.choosedPay === 'payWechat') {
          this.paySrc = item.data
          this.hasGetWechatCode = true
          this.dialogVisible = true
          return
        } else if (item.code === 200) {
          this.payHtml = item.data
          this.$nextTick(() => {
            document.forms[0].submit()
          })
        } else {
          this.$dialog.toast(item.msg)
        }
      })
    },
    countDown() {
      if (this.leftTime >= 0) {
        const now = new Date().getTime()
        this.leftTime = this.now + this.payTime * 1000 - now
        this.minutes = Math.floor(this.leftTime / 1000 / 60)
        this.seconds = Math.floor((this.leftTime / 1000) % 60)
      } else {
        clearInterval(this.timer)
        const {courseId} = this.$route.query
        this.$router.push({
          path: '/techVideoDetail',
          query: {id: courseId}
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-pintuan {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 200px;
  margin-bottom: 20px;
  .label-account {
    width: 120px;
    font-weight: 600;
    font-size: 20px;
  }
  span {
    color: #000;
    font-size: 24px;
  }
  .select {
    width: 200px;
  }
}

.line {
  width: 100%;
  height: 2px;
  margin-bottom: 48px;
  background: #f0f0f0;
  border-radius: 0px 0px 0px 0px;
}
.pintuan-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 48px;
}
.pintuan-info-show,
.yue {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
}
.juan-select {
  background: #5ad3cd;
  border-radius: 28px 28px 28px 28px;
  padding: 0 32px;
  font-family: PingFang HK, PingFang HK;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  line-height: 44px;
  cursor: pointer;
}
.title {
  padding-left: 20px;
  margin-bottom: 31px;
  img {
    width: 32px;
    height: 32px;
    margin-top: 2px;
    margin-right: 10px;
    display: inline-block;
    font-size: 0;
  }
  .name {
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 33px;
    display: inline-block;
    vertical-align: top;
  }
}
.page {
  .type-alipay,
  .type-wechat {
    background-size: 100% 100%;
  }
  .type-alipay {
    background-image: url(./../../assets/alipay.png);
  }
  .type-wechat {
    background-image: url(./../../assets/wechatPay.png);
  }
  .buyed-video {
    height: 800px;
  }
  .pay {
    width: 1200px;
    min-height: 800px;
    margin: 0 auto;
    .order {
      margin-top: 20px;
      padding: 38px 30px 30px 30px;
      width: 100%;
      background: #fff;
      .project {
        width: 100%;
        background: rgba(239, 239, 239, 0.5);
        border-radius: 12px 12px 12px 12px;
        margin-top: 31px;
        padding: 32px;
        display: flex;
        justify-content: space-between;
        .detail {
          font-size: 26px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 37px;
          max-width: 800px;
        }
        .price {
          height: 45px;
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 45px;
        }
      }
    }
    .pay-type {
      height: 536px;
      margin: 54px 30px 0 30px;
      .type-text {
        height: 45px;
        font-size: 32px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 45px;
        margin-bottom: 32px;
      }
      .type {
        width: 250px;
        height: 125px;
        margin-right: 60px;
        margin-bottom: 30px;
        display: inline-block;
        border: 3px solid #fff;
        background-color: #fff;
        border-radius: 12px;
        cursor: pointer;
        position: relative;
        .choosed-icon {
          width: 53px;
          height: 43px;
          position: absolute;
          bottom: 0;
          right: -1px;
        }
      }
      .choosed-type {
        border: 3px solid #ff0000;
      }
      .left-time {
        height: 37px;
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 37px;
        margin-bottom: 40px;
        .time {
          padding: 0 10px;
          color: #ff0000;
        }
      }
      .pay-price {
        float: right;
        .text {
          height: 45px;
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 45px;
        }
      }
    }
  }
  .pay-html {
    position: fixed;
    left: 100000;
  }
  .label {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #333333;
    text-align: left;
    margin-right: 16px;
  }
  .label-detail {
    height: 44px;
    border-radius: 12px 12px 12px 12px;
    border: 2px solid #acacac;
    padding: 0 32px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    color: #acacac;
    margin-right: 48px;
  }
  .pay-btn {
    width: 200px;
    height: 60px;
    line-height: 60px;
    color: #ffffff;
    text-align: center;
    background: #ff724c;
    font-weight: 500;
    border-radius: 30px;
    font-size: 28px;
    margin-top: 20px;
    cursor: pointer;
  }
  .pay-number {
    height: 70px;
    font-size: 40px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ff724c;
    line-height: 56px;
  }
  >>> .el-dialog__body {
    padding: 20px 20px;
  }
  >>> .el-dialog__header {
    padding: 0;
  }
}
.coupon-icon {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}
.coupon-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.coupon {
  width: 437px;
  height: 147px;
  margin-left: 53px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 23px;
  cursor: pointer;
}
.coupon-bg {
  background-image: url(/img/userinfo-icon/coupon-bg.png);
  background-size: 100% 100%;
}
.coupon--geted-bg {
  background-image: url(/img/userinfo-icon/coupon--geted-bg.png);
  background-size: 100% 100%;
}
.coupon-info {
  flex: 1;
  span {
    font-family: PingFang HK, PingFang HK;
    font-weight: 500;
    font-size: 24px;
    color: #b1864c;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
  .coupon-time {
    font-family: PingFang HK, PingFang HK;
    font-weight: 400;
    font-size: 20px;
    color: #c49d6a;
    line-height: 23px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
}
.coupon-price {
  width: 171px;
  box-sizing: border-box;
  padding-left: 32px;
  .price-icon {
    font-family: DINPro, DINPro;
    font-weight: bold;
    font-size: 16px;
    color: #ff724c;
    line-height: 38px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    display: inline-block;
    margin-right: 4px;
  }
  .price-value {
    font-family: DINPro, DINPro;
    font-weight: bold;
    font-size: 32px;
    color: #ff724c;
    line-height: 38px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
}
</style>
<style lang="scss">
.page {
  .dialog {
    /deep/ .el-dialog__header {
      padding: 0;
    }
  }
}
</style>
