import request from '@/utils/request'

export const memberOptions = params =>
  request({
    url: `/agentSale/memberOptions`,
    method: 'get',
    params
  })

export const agentSaleRefund = params =>
  request({
    url: `/agentSale/refund`,
    method: 'post',
    data: params
  })

export const productPrice = params =>
  request({
    url: `/agentSale/productPrice`,
    method: 'get',
    params
  })

export const createOrder = params =>
  request({
    url: '/agentSale/createOrder',
    method: 'post',
    data: params
  })

export const updateAgentSaleAuth = params =>
  request({
    url: '/agentSale/updateAgentSaleAuth',
    method: 'post',
    data: params
  })

export const getAgentSaleAuth = params =>
  request({
    url: `/agentSale/getAgentSaleAuth`,
    method: 'get',
    params
  })

export const qaList = params =>
  request({
    url: `/marketing/qaList`,
    method: 'get',
    params
  })
export const getTips = params =>
  request({
    url: `/marketing/tips`,
    method: 'get',
    params
  })
export const queryAgentSaleOrder = params =>
  request({
    url: `/agentSale/queryAgentSaleOrder`,
    method: 'get',
    params
  })
export const groupMessageList = params =>
  request({
    url: `/groupMessage/list`,
    method: 'get',
    params
  })

export const orderRecords = params =>
  request({
    url: `/marketing/orderRecords`,
    method: 'get',
    params
  })

export const registerList = params =>
  request({
    url: `/marketing/registerList`,
    method: 'get',
    params
  })

export const getGuidanceScenes = params =>
  request({
    url: `/marketing/scenes`,
    method: 'get',
    params
  })
export const groupMessage = params =>
  request({
    url: `/groupMessage/options`,
    method: 'get',
    params
  })

export const getGuidancePictures = params =>
  request({
    url: `/marketing/guidePics`,
    method: 'get',
    params
  })
export const saveGroupMessage = params =>
  request({
    url: `/groupMessage/save`,
    method: 'post',
    data: params
  })

export const removeGroupMessage = params =>
  request({
    url: `/groupMessage/remove`,
    method: 'post',
    data: params
  })

export const stopGroupMessage = params =>
  request({
    url: `/groupMessage/stop`,
    method: 'post',
    data: params
  })
export const startGroupMessage = params =>
  request({
    url: `/groupMessage/start`,
    method: 'post',
    data: params
  })

export const activityList = params =>
  request({
    url: `/activity/list`,
    method: 'get',
    params
  })

export const removeActivity = params =>
  request({
    url: `/activity/remove`,
    method: 'post',
    data: params
  })

export const stopActivity = params =>
  request({
    url: `/activity/offline`,
    method: 'post',
    data: params
  })
export const startActivity = params =>
  request({
    url: `/activity/online`,
    method: 'post',
    data: params
  })

export const saveActivity = params =>
  request({
    url: `/activity/save`,
    method: 'post',
    data: params
  })
export const outSiteList = params =>
  request({
    url: `/outSite/list`,
    method: 'get',
    params
  })

export const outSitePass = params =>
  request({
    url: `outSite/pass`,
    method: 'post',
    data: params
  })

export const outSiteReject = params =>
  request({
    url: `outSite/reject`,
    method: 'post',
    data: params
  })

export const platforms = params =>
  request({
    url: `/marketing/platforms`,
    method: 'get',
    params
  })

export const getProductPrice = params =>
  request({
    url: `/outSite/productPrice`,
    method: 'get',
    params
  })

export const createOutSiteOrder = params =>
  request({
    url: `outSite/createOrder`,
    method: 'post',
    data: params
  })

export const getCouponList = params =>
  request({
    url: `/coupon/list`,
    method: 'get',
    params
  })

export const getRecordList = params =>
  request({
    url: `/coupon/recordList`,
    method: 'get',
    params
  })

export const removeCoupon = params =>
  request({
    url: `/coupon/remove`,
    method: 'post',
    data: params
  })

export const saveCoupon = params =>
  request({
    url: `/coupon/save`,
    method: 'post',
    data: params
  })

export const rejectCoupon = params =>
  request({
    url: `/coupon/reject`,
    method: 'post',
    data: params
  })

export const passCoupon = params =>
  request({
    url: `/coupon/pass`,
    method: 'post',
    data: params
  })

export const applySendCoupon = params =>
  request({
    url: `/coupon/applySendCoupon`,
    method: 'post',
    data: params
  })

export const saveRelation = params =>
  request({
    url: `/relation/save`,
    method: 'post',
    data: params
  })

export const getRecommendList = params =>
  request({
    url: `/recommend/list`,
    method: 'get',
    params
  })

export const postRecommendStatus = params =>
  request({
    url: `/recommend/followup`,
    method: 'post',
    data: params
  })

export const createRecommend = params =>
  request({
    url: `/recommend/save`,
    method: 'post',
    data: params
  })

export const getGuideProducts = params =>
  request({
    url: `/marketing/guideProducts`,
    method: 'get',
    data: params
  })

export const relationList = params =>
  request({
    url: `/relation/list`,
    method: 'get',
    params
  })
export const relationPassed = params =>
  request({
    url: `/relation/passed`,
    method: 'post',
    data: params
  })

export const relationReject = params =>
  request({
    url: `/relation/reject`,
    method: 'post',
    data: params
  })

export const relationRemove = params =>
  request({
    url: `/relation/remove`,
    method: 'post',
    data: params
  })

export const relationReleased = params =>
  request({
    url: `/relation/released`,
    method: 'post',
    data: params
  })

export const competeList = params =>
  request({
    url: `/relation/competeList`,
    method: 'get',
    params
  })
