<template>
  <layoutContainer>
    <div class="enter-container">
      <div class="head">重设密码</div>
      <div class="container">
        <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="密码" label-width="120px" size="medium" label-position="right" prop="pass">
            <el-input
              type="password"
              v-model="ruleForm.payPassword"
              autocomplete="off"
              class="change-password-input"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" label-width="120px" size="medium" label-position="right" prop="checkPass">
            <el-input
              type="password"
              v-model="ruleForm.checkPass"
              autocomplete="off"
              class="change-password-input"
            ></el-input>
          </el-form-item>
          <el-form-item label="短信验证" label-width="120px" size="medium" label-position="right" prop="age" class="pr">
            <el-input placeholder="短信验证码" v-model="ruleForm.verifyCode" class="change-password-input"></el-input>
            <div>（发送至注册手机号）</div>
            <button @click.prevent="getCode()" class="code-btn" :disabled="!show">
              <span v-show="show">获取验证码</span>
              <span v-show="!show" class="count">{{ count }}</span>
            </button>
          </el-form-item>
          <div class="reset-password-btn" @click="resetPassWord">重设支付密码</div>
        </el-form>
      </div>
      <div class="notice">
        <img src="/img/userinfo-icon/notice.png" class="notice-icon" alt="" srcset="" />
        <div class="notice-content">在用余额支付时，会用到支付密码。</div>
      </div>
    </div>
  </layoutContainer>
</template>

<script>
import {getVerifyCodeApi, resetPayPassword} from '@/api/index'
import {currentLoginUser} from '@/api/project'
import md5 from 'blueimp-md5'

export default {
  mounted() {
    this.$nextTick(() => {
      const screenHeight = document.documentElement.clientHeight
      const headerHeight = document.getElementsByClassName('headerBox')[0].offsetHeight
      const footerHeight = document.getElementsByClassName('footer-container')[0].offsetHeight
      this.containerHeight = screenHeight - headerHeight - footerHeight - 70
    })
    currentLoginUser().then(res => {
      this.phoneNumber = res.data.phoneNumber
    })
  },
  data() {
    return {
      containerHeight: 0,
      phoneNumber: '',
      ruleForm: {
        payPassword: '',
        checkPass: '',
        verifyCode: ''
      },
      timer: null,
      count: 0,
      show: true
    }
  },
  methods: {
    resetPassWord() {
      const params = {
        payPassword: md5(this.ruleForm.payPassword),
        verifyCode: this.ruleForm.verifyCode
      }
      resetPayPassword(params).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.$router.go(-1)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getCode() {
      if (this.ruleForm.payPassword !== this.ruleForm.checkPass) {
        this.$message.warning('密码不一致')
        return
      }
      // 获取验证码
      // biz string: 0 注册  1 验证码登录  2 找回密码
      // submitType 1密码登录 2验证码登录 3注册新用户 4 找回密码
      getVerifyCodeApi({phone: this.phoneNumber, biz: 5})
      //axios请求
      // 验证码倒计时
      if (!this.timer) {
        this.count = 60
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.enter-container {
  width: 1200px;
  background: #ffffff;
  padding: 30px 0;
  border-radius: 12px;
  padding-bottom: 300px;
  .head {
    width: 165px;
    height: 62px;
    background: #5ad3cd;
    border-radius: 0 30px 30px 0;
    font-weight: 500;
    font-size: 24px;
    color: #ffffff;
    line-height: 62px;
    text-align: center;
  }
  .container {
    width: 500px;
    padding-top: 30px;
    margin: 0 auto;
  }
  .change-password-input {
    width: 300px;
  }
  .pr {
    position: relative;
  }
  .code-btn {
    width: 100px;
    height: 20px;
    position: absolute;
    top: 6px;
    right: -30px;
    z-index: 222;
    color: #ef8466;
    font-size: 14px;
    border: none;
    padding-left: 10px;
    background-color: #fff;
    cursor: pointer;
  }
  .reset-password-btn {
    width: 304px;
    height: 74px;
    background: #5ad3cd;
    border-radius: 40px 40px 40px 40px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 24px;
    color: #ffffff;
    line-height: 74px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin: 0 auto;
  }

  .notice {
    width: 600px;
    padding: 20px;
    background-color: #f6f6f8;
    display: flex;
    justify-content: flex-start;
    border-radius: 12px;
    margin-top: 20px;
    margin: 30px auto;
    .notice-icon {
      width: 48px;
      height: 48px;
    }
    .notice-content {
      flex: 1;
      margin-left: 30px;
      margin-top: 12px;
      line-height: 24px;
      font-size: 24px;
    }
  }
}
</style>
